import _ from 'lodash';

import { CollectionInstanceIdentifiers } from '@breathelife/types';

import {
  QuestionnaireDefinition,
  SectionGroup,
  Section,
  Subsection,
  Question,
  Field,
  RepeatableQuestionnaireNode,
  isSectionGroupRepeatable,
  isQuestionRepeatable,
  RepeatableSectionGroup,
  RepeatableQuestion,
  isOptionField,
  SelectOption,
  isRepeatableOptionsWithLimits,
} from '../structure';
import { assertUnreachable } from '../utils';

export enum RepetitionIntervalBoundary {
  minRepetitions = 'minRepetitions',
  maxRepetitions = 'maxRepetitions',
}

interface AnswerPathContext {
  // Repeatable instance paths to this node (most of the time array indices).
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers;
}

export abstract class ExpandedContextVisitor {
  private readonly repetitionIntervalBoundary: RepetitionIntervalBoundary;
  private answerPathContext: AnswerPathContext[] = [];

  protected constructor(repetitionIntervalBoundary: RepetitionIntervalBoundary) {
    this.repetitionIntervalBoundary = repetitionIntervalBoundary;
  }

  public visitQuestionnaire(questionnaire: QuestionnaireDefinition): void {
    if (!questionnaire?.length) return;

    for (const sectionGroup of questionnaire) {
      this.visitSectionGroup(sectionGroup);
    }
  }

  protected visitSectionGroup(sectionGroup: SectionGroup): void {
    if (!sectionGroup.sections?.length) return;

    if (isSectionGroupRepeatable(sectionGroup) && isRepeatableOptionsWithLimits(sectionGroup.options)) {
      const numberOfRepetitions: number = this.numberOfRepetitions(sectionGroup);
      _.times(numberOfRepetitions, (repetitionIndex: number) => {
        this.withAnswerPathContextFor(sectionGroup.nodeId, repetitionIndex, () => {
          this.visitRepeatedSectionGroup(sectionGroup);
        });
      });
    } else {
      for (const section of sectionGroup.sections) {
        this.visitSection(section);
      }
    }
  }

  protected visitRepeatedSectionGroup(sectionGroup: RepeatableSectionGroup): void {
    for (const section of sectionGroup.sections) {
      this.visitSection(section);
    }
  }

  protected visitSection(section: Section): void {
    if (!section.subsections?.length) return;

    for (const subsection of section.subsections) {
      this.visitSubsection(subsection);
    }
  }

  protected visitSubsection(subsection: Subsection): void {
    if (!subsection.questions?.length) return;

    for (const question of subsection.questions) {
      this.visitQuestion(question);
    }
  }

  protected visitQuestion(question: Question): void {
    if (!question.fields?.length) return;

    if (isQuestionRepeatable(question)) {
      const numberOfRepetitions: number = this.numberOfRepetitions(question);
      _.times(numberOfRepetitions, (repetitionIndex: number) => {
        this.withAnswerPathContextFor(question.nodeId, repetitionIndex, () => {
          this.visitRepeatedQuestion(question, repetitionIndex);
        });
      });
    } else {
      for (const field of question.fields) {
        this.visitField(field);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected visitRepeatedQuestion(question: RepeatableQuestion, repetitionIndex?: number): void {
    for (const field of question.fields) {
      this.visitField(field);
    }
  }

  protected visitField(field: Field): void {
    if (isOptionField(field)) {
      for (const option of field.options) {
        this.visitOption(option);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected visitOption(option: SelectOption): void {}

  protected answerPathContextFor(nodeId: string, repetitionIndex?: number): AnswerPathContext {
    const activeContext = this.activeContext();

    let repeatedInstanceIdentifiers: CollectionInstanceIdentifiers = activeContext
      ? activeContext.repeatedInstanceIdentifiers
      : {};

    if (typeof repetitionIndex !== 'undefined') {
      repeatedInstanceIdentifiers = {
        ...repeatedInstanceIdentifiers,
        [nodeId]: repetitionIndex,
      };
    }

    return {
      repeatedInstanceIdentifiers,
    };
  }

  private withAnswerPathContextFor(nodeId: string, repetitionIndex: number, callback: () => void): void {
    this.enterAnswerPathContext(nodeId, repetitionIndex);
    callback();
    this.exitAnswerPathContext();
  }

  private enterAnswerPathContext(nodeId: string, repetitionIndex: number): void {
    this.answerPathContext.push(this.answerPathContextFor(nodeId, repetitionIndex));
  }

  private exitAnswerPathContext(): void {
    if (this.answerPathContext.length === 0) {
      throw new Error('Invalid questionnaire expansion: Tried to exit an undefined answerPathContext');
    }
    this.answerPathContext.pop();
  }

  protected activeContext(): AnswerPathContext | undefined {
    return this.answerPathContext[this.answerPathContext.length - 1] ?? undefined;
  }

  protected previousContext(): AnswerPathContext | undefined {
    return this.answerPathContext[this.answerPathContext.length - 2] ?? undefined;
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    if (isRepeatableOptionsWithLimits(repeatableNode.options)) {
      switch (this.repetitionIntervalBoundary) {
        case RepetitionIntervalBoundary.minRepetitions:
          return repeatableNode.options.minRepetitions;
        case RepetitionIntervalBoundary.maxRepetitions:
          return repeatableNode.options.maxRepetitions;
      }
      return assertUnreachable(this.repetitionIntervalBoundary);
    }

    return 0;
  }

  protected repeatedInstanceIdentifiers(): CollectionInstanceIdentifiers {
    return this.activeContext()?.repeatedInstanceIdentifiers ?? {};
  }
}
