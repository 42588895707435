import _ from 'lodash';

import { getConditionNodeIds } from '@breathelife/condition-engine';
import { BooleanOperator, Conditions, FieldTypes } from '@breathelife/types';

import {
  DynamicOptions,
  isDynamicOptionField,
  isOptionField,
  isQuestionRepeatable,
  isReadOnlyField,
  isSectionGroupRepeatable,
  QuestionnaireDefinition,
} from '../../structure';

export type UnderRepeatableParents = {
  sectionGroup?: string;
  question?: string;
};

export type VisibilityConditionsWithNodeIdsToClear = {
  visibleIfConditions: Conditions;
  nodeIdsToClear: string[];
  optionsToClear?: OptionData[];
  dynamicOptionsDetails?: DynamicOptionsDetails;
  underRepeatableParents?: UnderRepeatableParents;
  selfRepeatable?: string;
};

type OptionData = {
  nodeId: string;
  optionId: string;
};

export type DynamicOptionsDetails = {
  dynamicOptions: DynamicOptions;
  nonDynamicIds: string[];
  fieldNodeId: string;
};

type NodeVisibilityConditions = { field: Conditions[]; selectOptions: Map<string, Conditions[]> };

/**
 * This class analyses a questionnaire to determine which `nodeId`s may be affected by a change to another `nodeId`.
 *
 * `visibleIf` conditions can be present on any questionnaire element (section, subsection, field, etc).
 * If a `visibleIf` condition evaluates to false then we may want to clear some or all of the `nodeId`s under that questionnaire element.
 * We can loop through the questionnaire to determine which `nodeId`s are present under each element.
 *
 * We can also extract the `nodeId`s from the `visibleIf` conditions to know when those conditions should be re-evaluated.
 */
class DependantConditionsMap {
  private readonly nodeIdToDependantConditionsWithNodeIdsToClear: Map<string, VisibilityConditionsWithNodeIdsToClear[]>;

  constructor() {
    this.nodeIdToDependantConditionsWithNodeIdsToClear = new Map<string, VisibilityConditionsWithNodeIdsToClear[]>();
  }

  public init(questionnaire: QuestionnaireDefinition): void {
    this.buildMap(questionnaire);
  }

  public getDependantConditions(nodeId: string): VisibilityConditionsWithNodeIdsToClear[] | undefined {
    return this.nodeIdToDependantConditionsWithNodeIdsToClear.get(nodeId);
  }

  /** Builds the nodeId -> (visibility-conditions and what-to-do-if-they-are-false) map.
   *
   * The basic idea is:
   * 1. Go through the questionnaire looking for visibleIf conditions
   * 2. When we find a condition extract all nodeIds used in that condition
   * 3. Map the nodeIds in (2) to the visibleIf condition,
   *     along with some information on what to do if the condition turns out falsy
   *     (this is why we're putting the fields' nodeIds in various lists,
   *     we'd need to clear out slightly different lists of nodeIds depending
   *     on what element the visibleIf condition is attached to).
   */
  private buildMap(questionnaire: QuestionnaireDefinition): void {
    questionnaire.forEach((sectionGroup) => {
      // Initialize a list of nodeIds for each element as we go down the questionnaire tree.
      const allNodeIdsUnderSectionGroup: string[] = [];
      let parentRepeatableSectionGroup: string | undefined = undefined;

      if (isSectionGroupRepeatable(sectionGroup)) {
        parentRepeatableSectionGroup = sectionGroup.nodeId;
        allNodeIdsUnderSectionGroup.push(sectionGroup.nodeId);
      }

      sectionGroup.sections.forEach((section) => {
        const allNodeIdsUnderSection: string[] = [];

        section.subsections.forEach((subsection) => {
          const allNodeIdsUnderSubsection: string[] = [];

          const editableQuestions = subsection.questions.filter(
            // Read-only fields should never trigger answers to clear.
            (question) => !(isQuestionRepeatable(question) && question.readOnly),
          );

          editableQuestions.forEach((question) => {
            const allNodeIdsUnderQuestion: string[] = [];
            let parentRepeatableQuestion: string | undefined = undefined;

            if (isQuestionRepeatable(question)) {
              // Removing the collection data as a whole avoids ending up with data like `beneficiaries: [{}, {}]` if we were to clear only using the `field.nodeId`.
              allNodeIdsUnderQuestion.push(question.nodeId);
              allNodeIdsUnderSubsection.push(question.nodeId);
              allNodeIdsUnderSection.push(question.nodeId);
              allNodeIdsUnderSectionGroup.push(question.nodeId);

              parentRepeatableQuestion = question.nodeId;
            }

            const editableFields = question.fields.filter(
              // Read-only fields should never trigger answers to clear.
              (field) => !isReadOnlyField(field) && field.type !== FieldTypes.information,
            );

            editableFields.forEach((field) => {
              // When analyzing the `*.visibleIf` conditions later in this function we need to know which data would be cleared if the condition is false.
              // Each parent element needs to know which `field.nodeId`s it contains; this is a convient place to record that (we could re-compute this before each `recordDependantVisibilityConditions` call, but it would be less efficient).
              allNodeIdsUnderQuestion.push(field.nodeId);
              allNodeIdsUnderSubsection.push(field.nodeId);
              allNodeIdsUnderSection.push(field.nodeId);
              allNodeIdsUnderSectionGroup.push(field.nodeId);

              if (field.visibleIf) {
                this.recordDependantVisibilityConditions({
                  visibleIfConditions: field.visibleIf,
                  nodeIdsToClear: [field.nodeId],
                  underRepeatableParents: {
                    question: parentRepeatableQuestion,
                    sectionGroup: parentRepeatableSectionGroup,
                  },
                });
              }

              if (isOptionField(field)) {
                field.options.forEach((selectOption) => {
                  if (selectOption.visibleIf) {
                    // We need to know the specific `optionId` when dealing with option visibility, since we want to clear only that option if this condition doesn't pass.
                    const selectOptionToClear = [{ nodeId: field.nodeId, optionId: selectOption.id }];
                    this.recordDependantVisibilityConditions({
                      visibleIfConditions: selectOption.visibleIf,
                      nodeIdsToClear: [],
                      optionsToClear: selectOptionToClear,
                      underRepeatableParents: {
                        question: parentRepeatableQuestion,
                        sectionGroup: parentRepeatableSectionGroup,
                      },
                    });
                  }
                });
              }

              if (isDynamicOptionField(field)) {
                // Dynamic option fields are an especially tricky case since the values themselves depend on the answer data.
                // We need to be able to recreate the options during the filtering process to have any hope of filtering things properly.
                const dynamicOptionsDetails: DynamicOptionsDetails = {
                  dynamicOptions: field.dynamicOptions,
                  fieldNodeId: field.nodeId,
                  nonDynamicIds: field.options?.map((option) => option.id) ?? [],
                };

                const emptyConditions = { conditions: [] };
                this.recordDependantVisibilityConditions({
                  visibleIfConditions: emptyConditions,
                  nodeIdsToClear: [field.nodeId],
                  dynamicOptionsDetails: dynamicOptionsDetails,
                  underRepeatableParents: {
                    question: parentRepeatableQuestion,
                    sectionGroup: parentRepeatableSectionGroup,
                  },
                });
              }
            });

            if (question.visibleIf) {
              // Coming back up out of the tree we now know which `nodeId`s should be cleared if this condition fails.
              this.recordDependantVisibilityConditions({
                visibleIfConditions: question.visibleIf,
                nodeIdsToClear: allNodeIdsUnderQuestion,
                underRepeatableParents: { sectionGroup: parentRepeatableSectionGroup },
                selfRepeatable: parentRepeatableQuestion,
              });
            }
          });

          if (subsection.visibleIf) {
            // Coming back up out of the tree we now know which `nodeId`s should be cleared if this condition fails (&etc for section and sectionGroup).
            this.recordDependantVisibilityConditions({
              visibleIfConditions: subsection.visibleIf,
              nodeIdsToClear: allNodeIdsUnderSubsection,
              underRepeatableParents: { sectionGroup: parentRepeatableSectionGroup },
            });
          }
        });

        if (section.visibleIf) {
          this.recordDependantVisibilityConditions({
            visibleIfConditions: section.visibleIf,
            nodeIdsToClear: allNodeIdsUnderSection,
            underRepeatableParents: { sectionGroup: parentRepeatableSectionGroup },
          });
        }
      });

      if (sectionGroup.visibleIf) {
        this.recordDependantVisibilityConditions({
          visibleIfConditions: sectionGroup.visibleIf,
          nodeIdsToClear: allNodeIdsUnderSectionGroup,
          selfRepeatable: parentRepeatableSectionGroup,
        });
      }
    });
  }

  /**
   * This function determines when an update to a given nodeId should trigger a condition to be re-evaluated,
   * and records the information needed to clear nodeId data if the evaluation resolves to `false`.
   *
   * Mutates `this.nodeIdToDependantConditionsWithNodeIdsToClear`.
   */
  private recordDependantVisibilityConditions({
    visibleIfConditions,
    dynamicOptionsDetails,
    nodeIdsToClear,
    optionsToClear,
    selfRepeatable,
    underRepeatableParents,
  }: VisibilityConditionsWithNodeIdsToClear): void {
    // Gets all `nodeId`s related to these conditions! This call is very important.
    const dependantNodeIdsSet = getConditionNodeIds(visibleIfConditions);
    const dependantNodeIds = Array.from(dependantNodeIdsSet.values());

    if (dynamicOptionsDetails) {
      // Dynamic options always depend on the collection they reference.
      dependantNodeIds.push(dynamicOptionsDetails.dynamicOptions.collection);
    }

    dependantNodeIds.forEach((nodeId) => {
      if (!this.nodeIdToDependantConditionsWithNodeIdsToClear.has(nodeId)) {
        this.nodeIdToDependantConditionsWithNodeIdsToClear.set(nodeId, []);
      }
      const dependantConditionList = this.nodeIdToDependantConditionsWithNodeIdsToClear.get(nodeId);

      if (dependantConditionList) {
        dependantConditionList.push({
          visibleIfConditions,
          nodeIdsToClear,
          optionsToClear: optionsToClear ?? [],
          dynamicOptionsDetails,
          underRepeatableParents: underRepeatableParents,
          selfRepeatable: selfRepeatable,
        });
      } else {
        // This should never run.
        throw Error('dependantConditionList should always exist.');
      }
    });
  }
}

class NodeVisibilityConditionsMap {
  private readonly nodeIdToNodeVisibility: Map<string, NodeVisibilityConditions>;
  private readonly nodeIdFieldUsageCount: Map<string, number>;

  constructor() {
    this.nodeIdToNodeVisibility = new Map();
    this.nodeIdFieldUsageCount = new Map();
  }

  public init(questionnaire: QuestionnaireDefinition): void {
    this.buildNodeIdToMultipleNodeVisibility(questionnaire);
  }

  public hasMultiNodeVisibilityConditions(nodeId: string): boolean {
    const nodeIdUsageCount = this.nodeIdFieldUsageCount.get(nodeId);
    return !!nodeIdUsageCount && nodeIdUsageCount > 1;
  }

  public getVisibilityConditions(nodeId: string): NodeVisibilityConditions | undefined {
    return this.nodeIdToNodeVisibility.get(nodeId);
  }

  private buildNodeIdToMultipleNodeVisibility(questionnaire: QuestionnaireDefinition): void {
    // The visibility of a given node is determined not only by its own conditions, but those of its ancestor nodes.
    // This code traverses the questionnaire to build 'wholistic' conditions which can be used determine if a given node is visible or not.
    questionnaire.forEach((sectionGroup) => {
      sectionGroup.sections.forEach((section) => {
        section.subsections.forEach((subsection) => {
          subsection.questions.forEach((question) => {
            // Build this here so it can be reused across fields with no visibleIf of their own.
            const questionVisibility: Conditions = {
              conditions: _.compact([
                sectionGroup.visibleIf,
                section.visibleIf,
                subsection.visibleIf,
                question.visibleIf,
              ]),
              operator: BooleanOperator.and,
            };

            question.fields.forEach((field) => {
              const usageCount = this.nodeIdFieldUsageCount.get(field.nodeId) ?? 0;
              this.nodeIdFieldUsageCount.set(field.nodeId, usageCount + 1);

              let recordedConditions = this.nodeIdToNodeVisibility.get(field.nodeId);
              if (!recordedConditions) {
                recordedConditions = { field: [], selectOptions: new Map() };
                this.nodeIdToNodeVisibility.set(field.nodeId, recordedConditions);
              }

              const fieldVisibility = field.visibleIf
                ? {
                    conditions: [...questionVisibility.conditions, field.visibleIf],
                    operator: BooleanOperator.and,
                  }
                : questionVisibility;

              recordedConditions.field.push(fieldVisibility);

              if (isOptionField(field)) {
                // Individual options should also only be deleted if they are invisible everywhere.
                field.options.forEach((selectOption) => {
                  const selectOptionVisibilityCondition = selectOption.visibleIf
                    ? {
                        conditions: [...fieldVisibility.conditions, selectOption.visibleIf],
                        operator: BooleanOperator.and,
                      }
                    : fieldVisibility;

                  let recordedOptionConditions = recordedConditions!.selectOptions.get(selectOption.id);
                  if (!recordedOptionConditions) {
                    recordedOptionConditions = [];
                    recordedConditions!.selectOptions.set(selectOption.id, recordedOptionConditions);
                  }

                  recordedOptionConditions.push(selectOptionVisibilityCondition);
                });
              }
            });
          });
        });
      });
    });
  }
}

export class VisibilityDependencyMap {
  private initialized: boolean = false;

  // Holds all visibility conditions _referencing_ a given nodeId.
  private readonly dependantConditions: DependantConditionsMap;

  // Holds conditions determining if a given nodeId is visible.
  private readonly nodeVisibilityConditions: NodeVisibilityConditionsMap;

  private readonly questionnaire: QuestionnaireDefinition;

  constructor(questionnaire: QuestionnaireDefinition) {
    this.dependantConditions = new DependantConditionsMap();
    this.nodeVisibilityConditions = new NodeVisibilityConditionsMap();
    this.questionnaire = questionnaire;
  }

  public init(): void {
    this.dependantConditions.init(this.questionnaire);
    this.nodeVisibilityConditions.init(this.questionnaire);
    this.initialized = true;
  }

  public getDependantConditions(nodeId: string): VisibilityConditionsWithNodeIdsToClear[] | undefined {
    if (!this.initialized) this.init();
    return this.dependantConditions.getDependantConditions(nodeId);
  }

  public getVisibilityConditions(nodeId: string): NodeVisibilityConditions | undefined {
    if (!this.initialized) this.init();
    return this.nodeVisibilityConditions.getVisibilityConditions(nodeId);
  }

  public hasMultiNodeVisibilityConditions(nodeId: string): boolean {
    if (!this.initialized) this.init();
    return this.nodeVisibilityConditions.hasMultiNodeVisibilityConditions(nodeId);
  }
}
