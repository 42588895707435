import { Language, Localizable } from '@breathelife/types';

import { ValidityRule } from './nodeEvaluation';

import {
  AgreeField,
  ButtonField,
  Field,
  InfoSupplement,
  isAgreeField,
  isButtonField,
  isOptionField,
  isPlaceholderField,
  isQuestionRepeatable,
  OptionField,
  PlaceholderField,
  Question,
  QuestionnaireDefinition,
  RepeatableQuestion,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
} from './structure';

export type TextGetter = (key: string, params?: { [key: string]: any }) => string;

export type Localized<T> = T extends (infer Item)[]
  ? Localized<Item>[]
  : T extends object
  ? {
      [K in keyof T]: T[K] extends Localizable | undefined
        ? string | undefined
        : T[K] extends Localizable
        ? string
        : T[K] extends object
        ? Localized<T[K]>
        : T[K];
    }
  : T;

export function localizeQuestionnaire(
  questionnaire: QuestionnaireDefinition,
  language: Language,
): Localized<QuestionnaireDefinition> {
  for (const sectionGroup of questionnaire) {
    localizeSectionGroup(sectionGroup, language);
  }

  return questionnaire as unknown as Localized<QuestionnaireDefinition>;
}

export function localizeSectionGroup(sectionGroup: SectionGroup, language: Language): Localized<SectionGroup> {
  const localizedSectionGroup = sectionGroup as unknown as Localized<SectionGroup>;

  localizedSectionGroup.title = localize(sectionGroup.title, language) ?? '';
  localizedSectionGroup.text = localize(sectionGroup.text, language);

  for (const section of sectionGroup.sections) {
    localizeSection(section, language);
  }

  return localizedSectionGroup;
}

export function localizeSection(section: Section, language: Language): Localized<Section> {
  const localizedSection = section as unknown as Localized<Section>;

  localizedSection.title = localize(section.title, language) ?? '';
  localizedSection.text = localize(section.text, language);

  for (const subsection of section.subsections) {
    localizeSubsection(subsection, language);
  }

  return localizedSection;
}

export function localizeSubsection(subsection: Subsection, language: Language): Localized<Subsection> {
  const localizedSubsection = subsection as unknown as Localized<Subsection>;

  localizedSubsection.title = localize(subsection.title, language) ?? '';
  localizedSubsection.text = localize(subsection.text, language);
  localizedSubsection.nextStepButtonText = localize(subsection.nextStepButtonText, language);

  for (const question of subsection.questions) {
    localizeQuestion(question, language);
  }

  return localizedSubsection;
}

export function localizeQuestion(question: Question, language: Language): Localized<Question> {
  const localizedQuestion = question as unknown as Localized<Question>;

  localizedQuestion.title = localize(question.title, language);
  localizedQuestion.text = localize(question.text, language);

  if (isQuestionRepeatable(question)) {
    const localizedRepeatedQuestion = localizedQuestion as Localized<RepeatableQuestion>;
    localizedRepeatedQuestion.addQuestionButtonText = localize(question.addQuestionButtonText, language) ?? '';
    localizedRepeatedQuestion.removeQuestionButtonText = localize(question.removeQuestionButtonText, language) ?? '';
  }

  for (const field of question.fields) {
    localizeField(field, language);
  }

  return localizedQuestion;
}

export function localizeField(field: Field, language: Language): Localized<Field> {
  const localizedField: Localized<Field> = field as unknown as Localized<Field>;

  localizedField.title = localize(field.title, language);
  localizedField.text = localize(field.text, language);
  localizedField.label = localize(field.label, language);

  if (isPlaceholderField(field)) {
    (localizedField as Localized<PlaceholderField>).placeholder = localize(field.placeholder, language);
  }

  if (field.info) {
    const localizedInfoSupplement = localizedField.info as unknown as Localized<InfoSupplement>;
    localizedInfoSupplement.text = localize(field.info.text, language) ?? '';
    localizedInfoSupplement.title = localize(field.info.title, language);
  }

  if (field.validIf?.length) {
    field.validIf.forEach((rule) => {
      const localizedRule = rule as unknown as ValidityRule<string>;
      localizedRule.message = localize(rule.message, language) as string;
    });
  }

  if (isOptionField(field)) {
    const localizedOptionField = localizedField as Localized<OptionField>;

    localizedOptionField.options = field.options.map((option) => {
      const optionTitle = localize(option.title, language);
      const optionText = localize(option.text, language) ?? '';
      const optionInfo = option.info
        ? {
            ...option.info,
            title: localize(option.info.title, language),
            text: localize(option.info.text, language) ?? '',
          }
        : undefined;

      return {
        ...option,
        text: optionText,
        title: optionTitle,
        info: optionInfo,
      } as Localized<SelectOption>;
    });
  } else if (isAgreeField(field)) {
    const localizedAgreeField = localizedField as Localized<AgreeField>;

    localizedAgreeField.confirmedLabel = localize(field.confirmedLabel, language) ?? '';
    localizedAgreeField.modalHeader = localize(field.modalHeader, language) ?? '';
    localizedAgreeField.modalText = localize(field.modalText, language) ?? '';
  } else if (isButtonField(field)) {
    const localizedButtonField = localizedField as Localized<ButtonField>;

    localizedButtonField.buttonText = localize(field.buttonText, language) ?? '';
  }

  return localizedField;
}

export function localize(input: Localizable | undefined, language: Language): string | undefined {
  if (!input) return undefined;
  return input[language];
}
