import { combineLabels, DataDescriptor, DataLabel } from '@breathelife/meta-cruncher';
import { Answers } from '@breathelife/types';

import { AnswerPath, NodeIdAnswersResolver } from '../answersResolver';
import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import {
  BaseNode,
  Field,
  Question,
  QuestionnaireDefinition,
  RepeatableQuestionnaireNode,
  Section,
  SectionGroup,
  Subsection,
} from '../structure';
import { createStack, Stack } from '../utils';

class DataDescriptorVisitor extends ExpandedContextVisitor {
  private readonly answersResolver: NodeIdAnswersResolver;
  private labelStack: Stack<DataLabel>;
  private descriptor: DataDescriptor;
  private answers: Answers;

  public constructor(answersResolver: NodeIdAnswersResolver, answers: Answers) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.answersResolver = answersResolver;
    this.answers = answers;
    this.labelStack = createStack();
    this.descriptor = {};
  }

  public visitQuestionnaire(questionnaire: QuestionnaireDefinition): DataDescriptor {
    this.descriptor = {};
    this.labelStack.push(DataLabel.Unknown);
    super.visitQuestionnaire(questionnaire);
    this.labelStack.pop();
    return this.descriptor;
  }
  protected visitSectionGroup(sectionGroup: SectionGroup): void {
    this.visitBaseNode(sectionGroup, super.visitSectionGroup.bind(this));
  }
  protected visitSection(section: Section): void {
    this.visitBaseNode(section, super.visitSection.bind(this));
  }
  protected visitSubsection(subsection: Subsection): void {
    this.visitBaseNode(subsection, super.visitSubsection.bind(this));
  }
  protected visitQuestion(question: Question): void {
    this.visitBaseNode(question, super.visitQuestion.bind(this));
  }
  protected visitField(field: Field): void {
    this.visitBaseNode(field, (field) => {
      if (!this.answersResolver.knowsId(field.nodeId)) {
        // If there is a missing nodeId, gracefully degrade instead of failing. Continue building the DataDescriptor
        return;
      }
      const collectionIdentifiers = this.repeatedInstanceIdentifiers();

      const existingLabel = this.answersResolver.getAnswer(
        this.descriptor,
        field.nodeId,
        collectionIdentifiers,
        this.answers,
      );

      const dataLabels =
        existingLabel && existingLabel !== this.currentActiveDataLabel
          ? combineLabels(existingLabel, this.currentActiveDataLabel)
          : this.labelStack.peek();

      this.answersResolver.setAnswer(dataLabels, this.descriptor, field.nodeId, collectionIdentifiers, this.answers);
    });
  }

  private get currentActiveDataLabel(): DataLabel {
    return this.labelStack.peek() ?? DataLabel.Unknown;
  }

  private visitBaseNode<T extends BaseNode>(node: T, continueVisit: (node: T) => void): void {
    if (node.dataLabel) {
      this.labelStack.push(node.dataLabel);
      continueVisit(node);
      this.labelStack.pop();
    } else {
      continueVisit(node);
    }
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        this.answers,
        repeatableNode.nodeId,
        this.repeatedInstanceIdentifiers(),
      ) ?? 1
    );
  }
}

export function generateAnswersDataDescriptor(
  questionnaire: QuestionnaireDefinition,
  nodeIdToAnswerPathMap: Map<string, AnswerPath>,
  answers: Answers,
): DataDescriptor {
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const visitor = new DataDescriptorVisitor(answersResolver, answers);
  return visitor.visitQuestionnaire(questionnaire);
}
