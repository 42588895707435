import _ from 'lodash';

import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import {
  BooleanOperator,
  CollectionInstanceIdentifiers,
  NodeInstance,
  Answers,
  IAnswerResolver,
  Timezone,
} from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from '../nodeEvaluation/visibleIf/filterVisibleAnswers';
import { Field, QuestionnaireDefinition } from '../structure';

class ComputedAnswersVisitor extends ExpandedRepetitionsVisitor {
  private visibilityDependencyMap: VisibilityDependencyMap;
  private changedNodeInstances: NodeInstance[] = [];
  private timezone: Timezone;

  constructor(
    answersResolver: IAnswerResolver,
    answers: Answers,
    visibilityDependencyMap: VisibilityDependencyMap,
    timezone: Timezone,
  ) {
    super(answersResolver, answers);
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.timezone = timezone;
  }

  public getComputedAnswers(questionnaire: QuestionnaireDefinition): Answers {
    this.visitQuestionnaire(questionnaire);
    return this.answers;
  }

  public getChangedNodeInstances(): NodeInstance[] {
    return _.uniqWith(this.changedNodeInstances, _.isEqual);
  }

  protected visitField(field: Field): void {
    const { nodeId, computedValue } = field;
    if (!computedValue) {
      return;
    }

    const collectionInstanceIdentifiers = this.repeatedInstanceIdentifiers();
    if (!this.isFieldVisible(field, collectionInstanceIdentifiers)) {
      return;
    }

    const computedFieldValue = evaluateQuery(
      computedValue,
      this.answers ?? {},
      this.answersResolver,
      collectionInstanceIdentifiers,
      this.timezone,
    );

    const currentAnswer = this.answersResolver.getAnswer(this.answers, nodeId, collectionInstanceIdentifiers);
    if (computedFieldValue === currentAnswer) {
      return;
    }

    this.answersResolver.setAnswer(computedFieldValue, this.answers, nodeId, collectionInstanceIdentifiers);
    this.changedNodeInstances.push({ id: nodeId, collectionInstanceIdentifiers });
  }

  private isFieldVisible(field: Field, collectionInstanceIdentifiers: CollectionInstanceIdentifiers): boolean {
    const fieldVisibilityConditions = this.visibilityDependencyMap.getVisibilityConditions(field.nodeId);
    const visibilityConditions = fieldVisibilityConditions?.field;
    const isFieldVisible =
      !visibilityConditions ||
      evaluateConditions(
        { conditions: visibilityConditions, operator: BooleanOperator.or },
        this.answers,
        this.answersResolver,
        collectionInstanceIdentifiers,
        this.timezone,
      ).isValid;
    return isFieldVisible;
  }
}

export function computedQuestionnaireAnswers(
  questionnaire: QuestionnaireDefinition,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  existingAnswers: Answers,
  timezone: Timezone,
): Answers {
  const computedAnswersVisitor = new ComputedAnswersVisitor(answersResolver, existingAnswers, dependencyMap, timezone);

  let updatedAnswers = computedAnswersVisitor.getComputedAnswers(questionnaire);

  // Setting computed answers may affect the visibility of other answers.
  for (const nodeInstance of computedAnswersVisitor.getChangedNodeInstances()) {
    updatedAnswers = filterVisibleAnswers(
      nodeInstance.id,
      dependencyMap,
      answersResolver,
      updatedAnswers,
      nodeInstance.collectionInstanceIdentifiers,
      timezone,
    );
  }

  return updatedAnswers;
}
