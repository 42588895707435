import { getConditionNodeIds } from '@breathelife/condition-engine';
import { Answers, IAnswerResolver } from '@breathelife/types';

import { getInitialFieldValue } from '../questionnaire';
import { Field } from '../structure';
import {
  TransitionField,
  TransitionNode,
  TransitionNodeWithMetadata,
  TransitionQuestion,
  TransitionQuestionnaire,
  TransitionSection,
  TransitionSubsection,
} from './Questionnaire';
import { RenderingField } from './RenderingQuestionnaire';
import { TransitionVisitor } from './TransitionVisitor';

type FieldTransform = (field: TransitionField, stopVisit: () => void) => void;
type SectionTransform = (section: TransitionSection, stopVisit: () => void) => void;
type SubsectionTransform = (section: TransitionSubsection, stopVisit: () => void) => void;
type QuestionTransform = (section: TransitionQuestion, stopVisit: () => void) => void;

type ConstructorCallbacks = {
  fieldTransform?: FieldTransform;
  sectionTransform?: SectionTransform;
  subsectionTransform?: SubsectionTransform;
  questionTransform?: QuestionTransform;
};

class TransformVisitor extends TransitionVisitor {
  private readonly fieldTransform?: FieldTransform;
  private readonly sectionTransform?: SectionTransform;
  private readonly subsectionTransform?: SubsectionTransform;
  private readonly questionTransform?: QuestionTransform;

  constructor(callbacks: ConstructorCallbacks) {
    super();
    this.fieldTransform = callbacks.fieldTransform;
    this.sectionTransform = callbacks.sectionTransform;
    this.subsectionTransform = callbacks.subsectionTransform;
    this.questionTransform = callbacks.questionTransform;
  }

  public visitField(field: TransitionField): void {
    if (this.fieldTransform) {
      this.fieldTransform(field, this.stopVisit);
    }
  }

  public visitSection(section: TransitionSection): void {
    if (this.sectionTransform) {
      this.sectionTransform(section, this.stopVisit);
    } else {
      super.visitSection(section);
    }
  }

  public visitSubsection(subsection: TransitionSubsection): void {
    if (this.subsectionTransform) {
      this.subsectionTransform(subsection, this.stopVisit);
    } else {
      super.visitSubsection(subsection);
    }
  }

  public visitQuestion(question: TransitionQuestion): void {
    if (this.questionTransform) {
      this.questionTransform(question, this.stopVisit);
    } else {
      super.visitQuestion(question);
    }
  }
}

export function transformFields(questionnaire: TransitionQuestionnaire, callback: FieldTransform): void {
  const visitor = new TransformVisitor({ fieldTransform: callback });
  visitor.visitQuestionnaire(questionnaire);
}

export function transformFieldsInSection(section: TransitionSection, callback: FieldTransform): void {
  const visitor = new TransformVisitor({ fieldTransform: callback });
  visitor.visitSection(section);
}

export function transformSections(questionnaire: TransitionQuestionnaire, callback: SectionTransform): void {
  const visitor = new TransformVisitor({ sectionTransform: callback });
  visitor.visitQuestionnaire(questionnaire);
}

export function transformSubsections(questionnaire: TransitionQuestionnaire, callback: SubsectionTransform): void {
  const visitor = new TransformVisitor({ subsectionTransform: callback });
  visitor.visitQuestionnaire(questionnaire);
}

export function transformQuestions(questionnaire: TransitionQuestionnaire, callback: QuestionTransform): void {
  const visitor = new TransformVisitor({ questionTransform: callback });
  visitor.visitQuestionnaire(questionnaire);
}

type QuestionnaireWithField<FieldType> = (TransitionNodeWithMetadata & {
  sections: (TransitionNode & {
    subsections: (TransitionNode & {
      questions: (TransitionNodeWithMetadata & {
        fields: FieldType[];
      })[];
    })[];
  })[];
})[];

type FieldWithoutValue = TransitionNodeWithMetadata & Pick<Field, 'nodeId' | 'relatesTo' | 'defaultIf'>;
export type FieldWithValue = TransitionNodeWithMetadata & Pick<RenderingField, 'value' | 'appendToKeyValue'>;

export function setFieldValues(
  questionnaire: QuestionnaireWithField<FieldWithoutValue>,
  answers: Answers,
  answersResolver: IAnswerResolver,
): void {
  transformFields(questionnaire, (field: FieldWithoutValue) => {
    const answer = answersResolver.getAnswer(answers, field.nodeId, field.metadata?.repeatedInstanceIdentifierContext);
    const fieldWithValue = field as unknown as FieldWithValue;
    fieldWithValue.value = typeof answer === 'undefined' ? getInitialFieldValue(field as RenderingField) : answer;

    if (field.defaultIf !== undefined) {
      field.appendToKeyValue = getAppendToKeyValueForFieldWithDefaultIf(field, answers, answersResolver);
    }

    if (field.relatesTo !== undefined) {
      const relatesToValue = answersResolver.getAnswer(
        answers,
        field.relatesTo,
        field.metadata?.repeatedInstanceIdentifierContext,
      );

      if (typeof relatesToValue !== 'undefined') {
        const appendToKeyValue = fieldWithValue.appendToKeyValue ?? '';
        fieldWithValue.appendToKeyValue = getAppendToKeyValue(appendToKeyValue, relatesToValue);
      }
    }
  });
}

function getAppendToKeyValueForFieldWithDefaultIf(
  field: FieldWithoutValue,
  answers: Answers,
  answersResolver: IAnswerResolver,
): string {
  let appendToKeyValue = '';
  const defaultIf = field.defaultIf ?? [];

  for (const rule of defaultIf) {
    if (rule.conditions) {
      const nodeIds = getConditionNodeIds(rule.conditions);

      for (const nodeId of nodeIds) {
        const relatesToValueAnswer = answersResolver.getAnswer(
          answers,
          nodeId,
          field.metadata?.repeatedInstanceIdentifierContext,
        );

        if (typeof relatesToValueAnswer !== 'undefined') {
          appendToKeyValue = getAppendToKeyValue(appendToKeyValue, relatesToValueAnswer);
        }
      }
    }
  }

  return appendToKeyValue || 'defaultValueCondition';
}

function getAppendToKeyValue(appendToKeyValue: string = '', relatesToValue: any): string {
  const delimiter = appendToKeyValue.length > 0 ? '.' : '';
  return `${appendToKeyValue}${delimiter}${relatesToValue}`;
}
