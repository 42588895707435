import _ from 'lodash';

import { InsuranceModule } from '@breathelife/types';

import { QuestionnaireDefinition, Section } from '../structure';

export function filterQuestionnaireSectionsByInsuranceModule(
  questionnaire: QuestionnaireDefinition,
  insuranceModules: InsuranceModule[],
): QuestionnaireDefinition {
  if (!insuranceModules.length) {
    return questionnaire;
  }

  return questionnaire.map((sectionGroup) => {
    const filteredSectionGroupSections = filterSectionsByInsuranceModules(sectionGroup.sections, insuranceModules);
    return { ...sectionGroup, sections: filteredSectionGroupSections };
  });
}

function filterSectionsByInsuranceModules(sections: Section[], insuranceModules: InsuranceModule[]): Section[] {
  return sections.filter((section) => {
    // include the sections without a filter
    if (!section.insuranceModuleFilter) return true;

    const matches = _.intersection(section.insuranceModuleFilter, insuranceModules);
    return !_.isEmpty(matches);
  });
}
